import { useApolloClient } from "@apollo/client/main.cjs";
import {
  Button,
  Checkbox,
  ContextualSaveBar,
  FormLayout,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Modal,
  OptionList,
  Page,
  Select,
  Text,
  TextContainer,
  TextField,
  Thumbnail,
  Tooltip,
} from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import {
  AlertMinor,
  ClipboardMinor,
  CollectionsMajor,
  ExternalSmallMinor,
  InfoMinor,
} from "@shopify/polaris-icons";
import { SELLING_PLAN_GROUP_HIDDEN_TAG, adminConfigRoutePrefix } from "@smartrr/shared/constants";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import {
  ISmartrrBundleCollections,
  ISmartrrBundleConfig,
  ISmartrrBundlePacks,
} from "@smartrr/shared/entities/SellingPlanGroup";
import { GetSellingPlanGroupsQuery } from "@smartrr/shared/shopifyGraphQL/api";
import { queryShopCollections } from "@smartrr/shared/shopifyGraphQL/purchasableCollections";
import { shopifyGidToNumber } from "@smartrr/shared/utils/ensureShopifyGid";
import { getPurchasableImage } from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { cloneDeep, isEmpty, isEqual, isUndefined, set } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { DeepExtractTypeSkipArrays } from "ts-deep-extract-types";

import { FixedSearch, ModalProductTitle } from "@vendor-app/app/_sharedComponents/BrowseProductsModal/components";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { navigateWithShopInQuery } from "@vendor-app/utils/navigateWithShopInQuery";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

import { getSellingPlanGroups } from "../../AdminSellingPlanGroupsRoute/utils";
import { Spinner } from "../../components/elements/Spinner/index";
import {
  LearnMoreAboutLink,
  LearnMoreIconWrapper,
  LearnMoreLinkContainer,
} from "../../components/elements/styles";
import { Bundle } from "../components/Bundle";
import { emptyBundle, emptyPack } from "../components/constants";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { CopyClipboard } from "../../AdminSellingPlanGroupsRoute/components/SellingPlan";
import { copyToClipboard } from "@smartrr/shared/utils/copyToClipboard";

type SellingPlanGroupNode = DeepExtractTypeSkipArrays<
  GetSellingPlanGroupsQuery,
  ["sellingPlanGroups", "edges", "node"]
>;

const IconContainer = styled.div`
  .Polaris-LegacyStack {
    align-items: center;
  }
  .Polaris-Thumbnail {
    height: 2.1rem;
  }
  img {
    border: 1px solid #c9cccf;
    border-radius: 4px;
    height: 30px;
    padding: 1px;
    width: 30px;
  }
`;

const CardWrapper = styled.div`
  .Polaris-LegacyCard__Section {
    padding-top: 0;
  }
`;

const BottomButtonGroup = styled.div`
  box-shadow: inset 0 1px 0 #e4e5e7;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
`;

interface ILayoutWrapperProps {
  hasSpecialCharacters: boolean;
  hasUpdatedBundleName: boolean;
}

const LayoutWrapper = styled.div<ILayoutWrapperProps>`
  .Polaris-FormLayout__Item:nth-child(3) {
    margin-top: ${({ hasSpecialCharacters, hasUpdatedBundleName }) =>
      hasSpecialCharacters || hasUpdatedBundleName ? "5px" : ""};
  }
`;

const Alert = styled.span`
  color: ${({ color }) => (color === "specialCharacters" ? "#d72c0d" : "#916A00")};
  display: flex;
  flex-direction: row;

  .Polaris-Icon {
    margin: 0 5px 0 0;
    min-height: 20px;
    min-width: 20px;
  }
`;

interface ISellingPlanFromDb {
  sellingPlanGroupProductIds: string[];
}
export function AdminBundleRouteWithData({
  bundle: initialBundle,
  onSave: onSaveCallback,
  onDiscard: onDiscardCallback,
  isCreatingNew,
}: {
  bundle: ISmartrrBundleConfig;
  onSave(newBundle: ISmartrrBundleConfig): void;
  onDiscard(): void;
  isCreatingNew: boolean;
}): JSX.Element {
  const organization = useActiveOrganizationSelector();
  const { addToast } = useToast();
  const apolloClient = useApolloClient();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  const [bundleInput, setBundleInput] = useState<ISmartrrBundleConfig>({ ...cloneDeep(initialBundle) });

  const [packInput, setPackInput] = useState<ISmartrrBundlePacks[]>([...cloneDeep(initialBundle.packs)]);
  const [isLoadingSPG, setIsLoadingSPG] = useState<boolean>();
  const [showProductsModal, setShowProductsModal] = useState<boolean>(false);
  const [showDeletedProductModal, setShowDeletedProductModal] = useState<boolean>(false);
  const [filteredPurchasables, setFilteredPurchasables] = useState<IPurchasable[]>([]);
  const [filteredPurchasablesSearchText, setFilteredPurchasablesSearchText] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const [sellingPlanGroups, setSellingPlanGroups] = useState<SellingPlanGroupNode[]>([]);
  const [sellingPlanFromDb, setSellingPlanFromDb] = useState<ISellingPlanFromDb[] | []>();
  const [hasBundleChanges, setHasBundleChanges] = useState(false);
  const [hasPackChanges, setHasPackChanges] = useState(false);
  const [productCollections, setProductCollections] = useState<ISmartrrBundleCollections[] | undefined>();
  const [selectedBundleProduct, setSelectedBundleProduct] = useState<string>();

  const spgProductIds: string[] | undefined = sellingPlanFromDb?.[0]?.sellingPlanGroupProductIds;
  const bundleSellingPlanGroup = sellingPlanGroups.find(spg => spg.id === initialBundle.sellingPlanGroupId);
  const purchasableOptions = filteredPurchasablesSearchText ? filteredPurchasables : purchasables;
  const bundleProduct = purchasables.find(p => p.shopifyId === bundleInput.bundleProduct);
  const productImage = getPurchasableImage(bundleProduct);
  const shopUrl = organization?.myShopifyDomain;
  const productNumId = bundleProduct && shopifyGidToNumber(bundleProduct?.shopifyId!);
  const hasSpecialCharacters = containsSpecialChars(bundleInput.name);
  const hasUpdatedBundleName = !isCreatingNew && !isEqual(bundleInput.name, initialBundle.name);
  const hasDeletedBundleProduct = !isCreatingNew && isUndefined(bundleProduct);

  useEffect(() => {
    fetchSellingPlanGroups();
    if (initialBundle.bundleProduct) {
      getShopCollections();
    }
  }, []);

  useEffect(() => {
    if (hasDeletedBundleProduct) {
      setShowDeletedProductModal(true);
    }
  }, []);

  useEffect(() => {
    getSellingPlanGroupFromDb();
  }, [bundleInput.sellingPlanGroupId]);

  useEffect(() => {
    setHasBundleChanges(!isEqual(initialBundle, bundleInput));
    setHasPackChanges(!isEqual(initialBundle.packs, packInput));
  }, [bundleInput, initialBundle, packInput]);

  useEffect(() => {
    setBundleInput({ ...cloneDeep(initialBundle) });
    setPackInput([...cloneDeep(initialBundle.packs)]);
  }, [initialBundle]);

  const clearVariants = () => {
    setPackInput([{ ...emptyPack }]);
  };

  function containsSpecialChars(value: string) {
    const specialChars = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~\-]/;
    return specialChars.test(value);
  }

  const updatePacksInput = useCallback(
    (index: number, properties: Record<string, any>) => {
      let pack = packInput[index];
      for (const [path, newValue] of Object.entries(properties)) {
        pack = set(pack, path, newValue);
      }

      setPackInput([...packInput.slice(0, index), pack, ...packInput.slice(index + 1)]);
    },
    [packInput]
  );

  const deleteBundle = useCallback(async (bundleId: string) => {
    if (bundleId) {
      const deleteReq = await typedFrontendVendorApi.deleteReq(`/bundle-config/${bundleId}`);
      if (deleteReq.type === "error") {
        addToast("Failed to delete bundle");
      } else {
        addToast("Bundle deleted successfully");
        navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles`);
      }
    } else {
      navigateWithShopInQuery(`${adminConfigRoutePrefix}/bundles`);
    }
  }, []);

  const getSellingPlanGroupFromDb = async () => {
    setIsLoadingSPG(true);

    const numericGroupId = bundleInput.sellingPlanGroupId!.replaceAll(/^\D+/g, "");
    const res = await typedFrontendVendorApi.getReq(`/selling-plan-group/${numericGroupId}`);
    if (res.type === "success") {
      setSellingPlanFromDb(res.body);
    } else {
      addToast("Failed Loading Selling Plan Group");
    }
    setIsLoadingSPG(false);
  };

  const fetchSellingPlanGroups = useCallback(async () => {
    setIsLoadingSPG(true);
    const res = await getSellingPlanGroups(apolloClient);

    setSellingPlanGroups(res);
    setIsLoadingSPG(false);
  }, []);

  const checkPacks = useCallback(() => {
    let errorMessage = "";
    for (const p of packInput) {
      if (isEmpty(p.buyWithVariant)) {
        errorMessage = "Select variant before savings";
      }
      if (isEmpty(p.collectionString)) {
        errorMessage = "Fill in collection before saving";
      }
      if (isEmpty(p.name)) {
        errorMessage = "Fill in variant name before saving";
      }
    }
    return isEmpty(errorMessage) ? undefined : errorMessage;
  }, [packInput]);

  const onSave = useCallback(async () => {
    const errorMessage = checkPacks();
    if (bundleInput.name === "") {
      addToast("Fill in bundle storefront label before saving");
      return;
    } else if (hasSpecialCharacters) {
      addToast("Remove special characters in bundle storefront label before saving");
      return;
    } else if (bundleInput.bundleProduct === "") {
      addToast("Select bundle product before saving");
      return;
    } else if (errorMessage) {
      addToast(errorMessage);
      return;
    }

    setIsSaving(true);
    onSaveCallback({
      ...bundleInput,
      packs: packInput,
    });
    setIsSaving(false);
  }, [packInput, initialBundle, bundleInput]);

  const onDiscard = useCallback(() => {
    setBundleInput({ ...cloneDeep(initialBundle) });
    setPackInput([...cloneDeep(initialBundle.packs)]);
  }, [initialBundle]);

  const getShopCollections = async () => {
    const res = await queryShopCollections(apolloClient, 250);
    if (res.type === "success") {
      const collectionsEdges = res.body.data.collections.edges;
      const collections = collectionsEdges?.map(node => ({ title: node.node.title, handle: node.node.handle }));
      setProductCollections(collections);
    } else {
      addToast("Failed loading collections");
    }
  };

  const closeProductsModal = useCallback(() => {
    setSelectedBundleProduct(undefined);
    setShowProductsModal(false);
  }, []);
  const closeDeletedProductModal = useCallback(() => setShowDeletedProductModal(false), []);

  const deletePack = useCallback((index: number) => {
    setPackInput(initialPack => [...initialPack.slice(0, index), ...initialPack.slice(index + 1)]);
  }, []);

  const onBundleUpdate = useCallback(
    (key: string, value: any) => setBundleInput(bundle => ({ ...bundle, [key]: value })),
    []
  );

  const confirmProduct = () => {
    getShopCollections();
    clearVariants();
    onBundleUpdate("bundleProduct", selectedBundleProduct);
    setShowProductsModal(false);
  };

  if (isLoadingSPG) {
    return (
      <LegacyStack vertical distribution="center" alignment="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <Page
      narrowWidth
      backAction={{
        content: "Return to all Bundles",
        url: `${adminConfigRoutePrefix}/bundles`,
      }}
      title={
        isCreatingNew
          ? isEmpty(bundleInput.name)
            ? "Creating bundle"
            : `${bundleInput.name}`
          : `Managing ${bundleInput.name}`
      }
    >
      <LegacyStack vertical>
        {!!isCreatingNew && (
          <LegacyCard sectioned>
            <LegacyStack vertical>
              <p>Select a subscription program to link to this bundle</p>
              <Select
                label=""
                options={sellingPlanGroups
                  .filter(group => !group.name.includes(SELLING_PLAN_GROUP_HIDDEN_TAG))
                  .map(group => ({
                    label: `${group.name} - ${group.options[0]}`,
                    value: group.id,
                  }))}
                onChange={(s, id) => {
                  onBundleUpdate("sellingPlanGroupId", s);
                  setBundleInput({
                    ...emptyBundle,
                    sellingPlanGroupId: s,
                  });
                }}
                value={bundleInput.sellingPlanGroupId}
                placeholder="Select One"
                helpText="Heads up—you won't be able to change the subscription program associated with this bundle after saving"
              />
            </LegacyStack>
          </LegacyCard>
        )}
        {!isCreatingNew && (
          <LegacyCard sectioned>
            <LegacyStack vertical>
              <LegacyStack distribution="equalSpacing">
                <p>Linked subscription program</p>
                <Link
                  onClick={() =>
                    navigateWithShopInQuery(
                      `${adminConfigRoutePrefix}/plans/${shopifyGidToNumber(bundleInput.sellingPlanGroupId)}`
                    )
                  }
                >
                  View subscription program
                </Link>
              </LegacyStack>
              {bundleInput.id ? (
                <React.Fragment>
                  <CopyClipboard>
                    <span className="copy-btn">
                      <Button
                        plain
                        icon={ClipboardMinor}
                        onClick={() => copyToClipboard(bundleInput.id, "Copied to clipboard", addToast)}
                      >
                        Copy
                      </Button>
                    </span>
                  </CopyClipboard>
                  <TextField label="Your bundle ID" autoComplete="off" value={bundleInput.id} disabled />
                </React.Fragment>
              ) : null}
              <Select
                label=""
                disabled
                onChange={() => null}
                value={bundleInput.sellingPlanGroupId}
                options={[
                  {
                    label: bundleSellingPlanGroup?.name || "",
                    value: bundleSellingPlanGroup?.name || "",
                  },
                ]}
              />
            </LegacyStack>
          </LegacyCard>
        )}
        {(isCreatingNew && bundleInput.sellingPlanGroupId.length > 0) || !isCreatingNew ? (
          <LegacyStack vertical>
            <CardWrapper>
              <LegacyCard
                sectioned
                title={
                  <LegacyStack distribution="equalSpacing">
                    <LegacyStack vertical spacing="extraTight">
                      <p>Bundle product</p>
                      <Text variant="bodyMd" as="span" color="subdued">
                        Link the bundle product in Shopify
                      </Text>
                    </LegacyStack>
                    <Button onClick={() => setShowProductsModal(true)}>Browse</Button>
                  </LegacyStack>
                }
              >
                <LayoutWrapper
                  hasSpecialCharacters={hasSpecialCharacters}
                  hasUpdatedBundleName={hasUpdatedBundleName}
                >
                  <FormLayout>
                    <IconContainer>
                      <LegacyStack alignment="center" spacing="tight">
                        <Icon source={CollectionsMajor} color="base" />
                        {(isCreatingNew && bundleInput.bundleProduct.length === 0) ||
                        (!initialBundle.bundleProduct && !isCreatingNew) ? (
                          <Text variant="bodyMd" as="span" color="subdued">
                            {"(None selected)"}
                          </Text>
                        ) : (
                          <LegacyStack spacing="tight">
                            <img
                              src={productImage || ""}
                              alt={bundleProduct?.purchasableName || "product Image"}
                            />
                            <Link external url={`https://${shopUrl}/admin/products/${productNumId}`}>
                              {bundleProduct?.purchasableName}
                            </Link>
                          </LegacyStack>
                        )}
                      </LegacyStack>
                    </IconContainer>
                    <TextField
                      autoComplete="off"
                      label="Bundle storefront label"
                      helpText={
                        !hasSpecialCharacters &&
                        !hasUpdatedBundleName &&
                        "We recommend using the same name as the bundle product in Shopify"
                      }
                      value={bundleInput.name}
                      onChange={name => onBundleUpdate("name", name)}
                      placeholder="Example: Variety Case"
                    />
                    {!!hasSpecialCharacters && (
                      <Alert color="specialCharacters">
                        {" "}
                        <Icon source={AlertMinor} color="critical" />
                        Please remove special characters (ex. . ! @ # &)
                      </Alert>
                    )}
                    {!isCreatingNew && !!hasUpdatedBundleName && (
                      <Alert>
                        <Icon source={AlertMinor} color="warning" /> Note: If you change this, you must manually
                        update your page.smartrr-bundle.liquid file to match the new bundle storefront label
                      </Alert>
                    )}

                    <LegacyStack spacing="loose" vertical>
                      <LegacyStack spacing="tight" alignment="center">
                        <Checkbox
                          label="Use line items"
                          checked={bundleInput.useLineItems}
                          onChange={() => onBundleUpdate("useLineItems", !bundleInput.useLineItems)}
                        />
                        <Tooltip content="When line items are enabled, Smartrr will automatically update the linked Shopify order to include bundle contents. Bundle items will be added at a 100% discount, enabling easier fulfillment at a line-item level.">
                          <Icon source={InfoMinor} color="base" />
                        </Tooltip>
                      </LegacyStack>
                      <LegacyStack spacing="tight" alignment="center">
                        <Checkbox
                          label="Make items exclusive to this bundle"
                          checked={bundleInput.hideBundleItems}
                          onChange={() => onBundleUpdate("hideBundleItems", !bundleInput.hideBundleItems)}
                        />
                        <Tooltip content="Allow customers to exclusively purchase the items within selected collection(s) from bundle subscriptions only. When enabled, customers won't be able purchase any of the items in this configuration on a standard subscription.">
                          <Icon source={InfoMinor} color="base" />
                        </Tooltip>
                      </LegacyStack>
                    </LegacyStack>
                  </FormLayout>
                </LayoutWrapper>
              </LegacyCard>
            </CardWrapper>
            {packInput.map((pack, ind) => (
              <Bundle
                key={ind}
                index={ind}
                packInput={packInput}
                bundleProduct={bundleProduct}
                productCollections={productCollections}
                pack={pack}
                setPackInput={setPackInput}
                onDeletePack={(index: number) => deletePack(index)}
                updatePacksInput={updatePacksInput}
                organization={organization}
              />
            ))}{" "}
          </LegacyStack>
        ) : null}
        <BottomButtonGroup>
          <Button outline destructive onClick={() => deleteBundle(bundleInput.id!)}>
            Delete bundle
          </Button>
          <Button primary onClick={onSave}>
            Save
          </Button>
        </BottomButtonGroup>
      </LegacyStack>
      <Modal
        limitHeight
        title={
          <Text variant="headingLg" as="p">
            Browse products
          </Text>
        }
        open={showProductsModal}
        onClose={closeProductsModal}
        primaryAction={{
          content: "Confirm",
          onAction: confirmProduct,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeProductsModal,
          },
        ]}
      >
        <FixedSearch>
          <Modal.Section>
            <TextField
              autoComplete="off"
              label=""
              placeholder="Search products"
              value={filteredPurchasablesSearchText}
              onChange={text => {
                setFilteredPurchasablesSearchText(text);
                setFilteredPurchasables(() => {
                  return purchasables.filter(p =>
                    p.purchasableName.toLocaleLowerCase().includes(text.toLocaleLowerCase())
                  );
                });
              }}
            />
          </Modal.Section>
          <Modal.Section>
            {isEmpty(spgProductIds) ? (
              <TextContainer>Subscription program has no products</TextContainer>
            ) : (
              <OptionList
                onChange={val => {
                  setSelectedBundleProduct(val[0]);
                }}
                selected={[selectedBundleProduct ?? bundleInput.bundleProduct]}
                options={
                  purchasableOptions
                    .filter(
                      p =>
                        !!p.shopifyId &&
                        !p.isDraftOrArchived &&
                        spgProductIds?.includes(p.shopifyId.replaceAll(/^\D+/g, ""))
                    )
                    .sort()
                    .reduce((acc: OptionDescriptor[], p) => {
                      const imageUrl = getPurchasableImage(p);
                      const label = <ModalProductTitle>{p.purchasableName}</ModalProductTitle>;
                      const result: OptionDescriptor = {
                        label,
                        value: p.shopifyId!,
                        ...(imageUrl && {
                          media: <Thumbnail size="small" source={imageUrl} alt={p.purchasableName} />,
                        }),
                      };
                      acc.push(result);
                      return acc;
                    }, []) || []
                }
              />
            )}
          </Modal.Section>
        </FixedSearch>
      </Modal>
      <Modal
        title={
          <Text variant="headingLg" as="p">
            The product associated with this bundle has been deleted. Please delete this bundle to continue
          </Text>
        }
        open={showDeletedProductModal}
        onClose={closeDeletedProductModal}
        primaryAction={{
          content: "Delete",
          onAction: async () => await deleteBundle(bundleInput.id!),
          destructive: true,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: closeDeletedProductModal,
          },
        ]}
      ></Modal>
      {!!(hasBundleChanges || hasPackChanges) && (
        <ContextualSaveBar
          message="Unsaved changes"
          discardAction={{
            content: "Discard",
            onAction: onDiscard,
          }}
          saveAction={{
            loading: isSaving,
            content: "Save",
            onAction: onSave,
          }}
        />
      )}
      <div className="docs-link">
        <LegacyStack distribution="center" spacing="tight">
          <Icon source={InfoMinor} color="highlight" />
          <LearnMoreLinkContainer>
            Learn more about&nbsp;
            <LearnMoreAboutLink
              href="https://help.smartrr.com/docs/support/admin-portal/what-are-bundles"
              rel="noreferrer"
              target="_blank"
            >
              bundles
              <LearnMoreIconWrapper>
                <Icon source={ExternalSmallMinor} color="base" />
              </LearnMoreIconWrapper>
            </LearnMoreAboutLink>
          </LearnMoreLinkContainer>
        </LegacyStack>
      </div>
    </Page>
  );
}
